.matchingbride-wrapper {
  .light-squreblue {
    position: relative;
    top: -18px;
  }
  .religion-gap {
    margin-right: 10px;
  }
  .mg-right10 {
    margin-right: 10px;
  }
  .btn-back {
    color: #2e384d;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    font-family: "Avenir LT Std";
  }

  .matching-bride-table {
    border: 1px solid #ddd;
  }

  .matching-bride-table td {
    width: 130px;
  }
}
.btn-outline-secondary{
  margin: 10px 0px 0px 15px;
}
.matchingpersontab-wrapper {
  .match-profile-details-slide {
    background: #4b58ab 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    color: #fff !important;
    padding: 0px 10px 10px 10px;
  }
  .btn-white {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #4b58ab;
    font-family: "Avenir LT Std";
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    margin-top: 15px;
  }
  .bg-personal-hist-wrapper .match-fliter-tab .nav-link.active {
    background: #4b58ab44 0% 0% no-repeat padding-box;
    border: 1px solid #6994ec26;
    color: #4b58ab;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
  }
}

.light-squreblue {
  position: relative;
  top: -18px;
}

.matchstatuspage-wrapper {
  .accept-pending-box {
    padding: 0px 0px 0px 0px;
    height: 100%;
    margin-right: 7px;
    width: 32%;
    margin-bottom: 0px;
  }
  .bride-response-box-wrapper {
    display: flex;
  }
  .religion-gap {
    margin-right: 20px;
  }
  .mg-right10 {
    margin-right: 10px;
  }
  .accepted-text {
    margin-left: 10px;
    // color: #1ec2b1;
  }
  .accept-pending-wrapper-box {
    padding: 10px 0px 10px 0px;
  }
  .pending-text {
    margin-left: 10px;
    color: #898e97;
  }
}

.Requestwedding-wrapper {
  .verfication-name-text {
    font-weight: 900;
  }
  .bride-rquest-details {
    margin: 10px 10px 20px 10px;
  }
  .religion-gap {
    margin-right: 10px;
  }
  .mg-right10 {
    margin-right: 10px;
  }
  .btn-theme-darkblue {
    background: #4b58ab 0% 0% no-repeat padding-box;
    border: 1px solid #4b58ab;
    border-radius: 5px;
    letter-spacing: 0px;
    color: #ffffff;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 20px;
  }
  .request-card-wrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #cbcbcbba;
    border-radius: 5px;
    margin-right: 20px;
  }
  .vertical-grey-line {
    display: block;
    width: 100%;
    border: 1px solid #f1f1f1;
  }
  .line-up-topset {
    position: relative;
    top: -15px;
    width: 85%;
    left: 39px;
  }
  .mg-top-wedding-btn {
    margin-top: 30px;
  }
}

.matchingFliter-wrapper {
  .bg-fliter-wrapper {
    background: #293058 0% 0% no-repeat padding-box;
    border-radius: 0px 5px 5px 0px;
    color: #fff;
    padding: 10px 20px 30px 20px;
  }
  .fliter-label-group {
    color: #fff;
  }
  .btn-white {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #4b58ab;
    font-family: "Avenir LT Std";
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    display: block;
    width: 100%;
    height: 44px;
    margin-top: 15px;
  }
  .border-white-bg {
    border: 1px solid #fdfdfd;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
  }
  .border-white-bg option {
    color: #000;
  }
  .color-white {
    color: #fff;
  }
  .fliter-search-icon {
    width: 20px;
    height: 20px;
    position: relative;
    top: -4px;
    margin-right: 10px;
  }
}

.suitableFliter-wrapper {
  .suitable-match-tabs {
    .suitable-box-user {
      width: 100%;
      height: 186px;
    }
    .fliter-search-icon {
      width: 20px;
      height: 20px;
      position: relative;
      top: -4px;
      float: right;
    }
  }
  .btn-compare {
    border: 1px solid #4b58ab;
    border-radius: 5px;
    color: #4b58ab;
    font-family: "Avenir LT Std";
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    padding: 10px 20px;
    margin-top: 5px;
  }
}

.dashboard-wrapper {
  .dashboard-status-text {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000008;
    border: 1px solid #f9f9f9;
    border-radius: 4px;
    padding: 10px 10px 10px 10px;
    margin: 15px 10px 15px 10px;
    width: 22%;
  }

  .status-count-box {
    width: 45px;
    height: 45px;
    border-radius: 10px;
    background: #4b58ab 0% 0% no-repeat padding-box;
    display: block;
  }
  .status-count-text {
    color: #fff;
    display: block;
    text-align: center;
    padding: 10px 0px;
  }
  .status-match-text {
    letter-spacing: 0px;
    color: #2e384d;
    font-family: "Avenir LT Std";
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    margin-left: 10px;
  }
  .bg-green-color {
    background: #f4f9ff 0% 0% no-repeat padding-box;
  }
  .pink-color {
    background: #f58ead 0% 0% no-repeat padding-box;
  }
  .sky-blue {
    background: #2680eb 0% 0% no-repeat padding-box;
  }
  .orange-color {
    background: #e3710e 0% 0% no-repeat padding-box;
  }
  .custom-dashboard-table {
    font-size: 14px;
  }
  .dashboard-bg-wrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000008;
    border: 1px solid #f9f9f9;
    border-radius: 4px;
    padding: 0px 10px 0px 10px;
  }
  .custom-dashboard-table tbody {
    background: #f4f9ff 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }
  .custom-dashboard-table tbody tr {
    padding-bottom: 10px;
  }
}

.matching-link-page {
  width: 100%;
  display: contents;
}

.dashboard-piechart-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000008;
  border: 1px solid #f9f9f9;
  border-radius: 4px;
  padding: 20px 20px;

  .donutContainer {
    display: block;
  }
  .rate-matches {
    display: flex;
    flex-direction: row;
  }
  .rate-matches h4 {
    width: 50%;
  }
  .font-light {
    font-weight: 200;
  }
  .wid55 {
    width: 55%;
  }
  .donutContainer .circle {
    width: 230px;
    height: 230px;
    margin-bottom: 30px;
  }
  .donutContainer .details h2 {
    display: none;
  }
  .donutContainer .details h1 {
    display: none;
  }
}

.matching-image {
  width: 105px;
  height: 105px;
}
.compare-btn {
  background: #4B58AB 0% 0% no-repeat padding-box;
   border-radius: 5px;
   width: 153px;
height: 48px;
color: #fff;
margin-top: 20px;
}
.btn-close {
  position: absolute;
  right: 20px;
}
.example {
  margin-bottom: 10px;
}
.verification-box-users {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6994ecb5;
  width: 100%;
  display: flex;
  margin-right: 20px;
  padding: 10px 10px 0px 10px;
  height: 140px;
  margin-bottom: 20px;
  @media screen and (max-width:992px){
    width: 100%;
  }
}
.accept-pending-boxs {
  padding: 0px 0px 0px 0px;
  height: 100%;
  margin-right: 7px;
  width: 100%;
  margin-bottom: 0px;
}
.img-matchstatus {
  width: 85px;
  height: 100px;
}
.bride_photo{
  width: 145px;
  height: 145px;
  display: inline-block;
}
.suitable-Img {
  width: 110px;
  height: 116px;
  margin-bottom: 13px;
}
.disable {
  opacity: 0.6;
  cursor: not-allowed !important;
  margin-right: 10px;
}
.enable {
  opacity: 1;
  cursor: pointer;
}
.button_arrow {
  border: 1px solid #fff;
  padding: 6px 12px 6px 12px;
  background: none;
  color: #fff;
  margin-right: 10px;
}
.button_arrow:hover {
  border: 1px solid #fff;
  padding: 6px 12px 6px 12px;
  background: none;
  color: #fff;
}
.matching-expert-inputlist {
  display: inline-flex;
  width: 100%;
}
.search-submit {
 margin: 0px 12px 0px 12px;
 padding: 6px 12px 6px 12px;
 background: #4b58ab;
 color: #fff;
 border-radius: 6px;
}
.search-cancel {
margin: 0px 12px 0px 0px;
padding: 6px 12px 6px 12px;
background-color: red;
color: #fff;
border-radius: 6px;
}

@media only screen and (max-width: 640px){
  .suitableFliter-wrapper {
    .suitable-match-tabs {
      .fliter-search-icon {
        float: inherit;
      }
    }
  }
}