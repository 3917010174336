@font-face {
  font-family: "Avenir LT Std";
  src: url("../fonts/AvenirLTStd-Roman.eot");
  src: local("Avenir LT Std 55 Roman"), local("AvenirLTStd-Roman"),
    url("../fonts/AvenirLTStd-Roman.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/AvenirLTStd-Roman.woff2") format("woff2"),
    url("../fonts/AvenirLTStd-Roman.woff") format("woff"),
    url("../fonts/AvenirLTStd-Roman.ttf") format("truetype"),
    url("../fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.adminregistered-candidate-wrapper {
  background: #f4f9ff 0% 0% no-repeat padding-box;
  height: 100vh;
}

.admin-white-bg-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000008;
  border: 1px solid #f9f9f9;
  border-radius: 4px;
  margin: 20px 0px 0px 20px;
  height: 650px;
  left: 20px;
}
.white-verification-userbox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000008;
  border: 1px solid #6994ec26;
  border-radius: 4px;
  padding: 20px 20px 20px 20px;
  margin: 0px 20px 20px 0px;
  width: 23%;
}
.candidate-name-text-p {
  letter-spacing: 0px;
  color: #444758;
  font-size: 14px;
  line-height: 20px;
  font-family: "Avenir LT Std";
  font-weight: bold;
}
.candidate-user-id-hfn {
  letter-spacing: 0px;
  color: #4b58ab;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.candidate-user-calerder {
  letter-spacing: 0px;
  color: #46484b;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}
.pad-20-0 {
  padding: 20px 0px 0px;
}

/* CUSTOM HEADER START */

/* .blue-sidebar-wrapper {
  
  } */

.sidebar-wrapper-style {
  background: #171e4a 0% 0% no-repeat padding-box;
  width: 81px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
}

.mg-left-navbar {
  margin-left: 6%;
}

.propo-small-blue-con {
  margin-left: 30px;
}

/* CUSTOM HEADER END */
.vertical-nav-item-bg-white {
  background: #fcfdfd 0% 0% no-repeat padding-box;
  border: 1px solid #ececec;
  height: 100vh;
  position: relative;
  width: 175px;
  float: left;
}

.veritical-document-nav {
  margin-left: 10%;
}

.veritical-document-nav .nav-link {
  letter-spacing: 0px;
  color: #2e384d;
  font-size: 14px;
  line-height: 18px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  padding: 10px 10px 10px 10px;
}

.veritical-document-nav .nav-link.active {
  //  border-bottom: 2px solid rgb(65 154 213);
  color: #171e4a;
  font-weight: bold;
}
.admin-blue-title-text {
  letter-spacing: 0px;
  color: #4b58ab;
  font-size: 24px;
  line-height: 30px;
}
.proposal-grey-text {
  letter-spacing: 0px;
  color: #2e384d;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
}
.admin-bottom-text-item {
  position: absolute;
  bottom: 80px;
  right: 9px;
}
#myCheckbox1 {
  position: absolute;
  right: 8px;
  top: 12px;
}
.td-wid60 {
  width: 68%;
}
.td-wid40 {
  width: 35%;
}
.registered-tr-head {
  letter-spacing: 0px;
  color: #444758;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 20px;
}
.register-popup-head-bg {
  background: #4b58ab 0% 0% no-repeat padding-box !important;
  border-radius: 4px 4px 0px 0px !important;
  letter-spacing: 0px;
}

.register-popup-head-bg h4 {
  color: #ffffff;
  font-family: "Avenir LT Std";
}
.img-document {
  width: 50%;
  margin: 0 auto;
}
.blue-icon-dis {
  margin-right: 10px;
}
.btn-approve-blue {
  background: #4b58ab 0% 0% no-repeat padding-box;
  border-radius: 5px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 10px 30px;
}
.btn-approve-blue:hover {
  background: #4b58ab 0% 0% no-repeat padding-box;
  border-radius: 5px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 10px 30px;
}
.btn-white-reject {
  background: #6994ec26 0% 0% no-repeat padding-box;
  padding: 10px 10px;
  letter-spacing: 0px;
  border: 1px solid #d1d1d1;
  margin-right: 20px;
  background-color: transparent;
  color: #4b58ab;
  border-radius: 5px;
}
.btn-white-reject:hover {
  background: #6994ec26 0% 0% no-repeat padding-box;
  padding: 10px 10px;
  letter-spacing: 0px;
  border: 1px solid #d1d1d1;
  margin-right: 20px;
  background-color: transparent;
  color: #4b58ab !important;
  border-radius: 5px;
}
.btn-white-reject span img {
  margin-left: 10px;
}
.admin-heading-title-h4 {
  letter-spacing: 0px;
  color: #171e4a;
  font-size: 17px;
  font-family: "Avenir LT Std";
  font-weight: bold;
}
.td-pad-bottom20 td {
  padding-bottom: 10px;
}
.mg-top20 {
  margin-top: 20px;
}
.verification-box-user {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #6994ecb5;
  width: 30%;
  display: flex;
  margin-right: 20px;
  padding: 10px 10px 0px 10px;
  height: 140px;
  margin-bottom: 20px;
}
.two-equl-wrapper {
  display: block;
  margin-left: 70px;
}
.verfication-name-text {
  letter-spacing: 0px;
  color: #2e384d;
  font-size: 16px;
  line-height: 20px;
  font-family: "Avenir LT Std";
  margin-bottom: 4px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.candidate-verification-img {
  height: 115px;
  width: 115px !important;
}
.verify-width {
  width: 40%;
}
.verfication-id-text {
  letter-spacing: 0px;
  color: #4b58ab;
  font-family: "Avenir LT Std";
  font-size: 12px;
  line-height: 20px;
}
.verification-name-list {
  margin: 0px 0px 0px 15px;
}
.form-check-gap-input {
  width: 22%;
  margin-right: 10px;
  margin-bottom: 20px;
}

.photo-attchements-pic {
  margin-right: 25px;
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
}
.textarea-height {
  height: 102px;
}
.document-attachments-box-wrapper {
  width: 17%;
  height: 45px;
  border: 1px solid #6994ec26;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 0px 5px 10px 5px;
  font-size: 12px;
  color: #898e97;
}
.wid30 {
  margin-right: 30px;
  width: 29%;
  // display: flex;
  height: fit-content;
}
.form-details-box {
  width: 30%;
  display: block;
  margin-right: 15px;
  margin-left: 25px;
}
.title-details-edit-icons {
  position: relative;
  width: 100%;
}
.edit-pencil-icon-position {
  position: absolute;
  top: 15px;
  right: -1px;
}

.cutom-form-selecttext .form-group label {
  align-self: start;
  background-image: linear-gradient(
    to top,
    #fff 0 calc(50% - 2px),
    #fff calc(50% - 2px)
  );
  font-size: 14px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  transform: translate(0.5rem, 84%);
  width: max-content;
}
.border-select-wrapper {
  border: 1px solid #d7d7d7;
  padding: 0px 20px;
}
.timeline-account-list {
  background: 1px dotted #777;
  margin: 20px auto;
  padding: 20px;
}
.outer-name-list {
  border-left: 2px dotted #a096a5;
}

.card-account-name {
  position: relative;
  margin: 0 0 0px 20px;
  padding: 10px 10px 0px 10px;
  color: #2e384d;
}

.info-document {
  display: flex;
  flex-direction: column;
}
.document-submitted-text {
  color: #2e384d;
  position: relative;
  font-size: 14px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
}
.document-submitted-text::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #a096a5;
  border-radius: 999px;
  left: -39px;
  border: 3px solid #a096a5;
}
.name-by-text {
  font-size: 12px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  margin-bottom: 5px;
}
.flex-column-container {
  display: flex;
  flex-direction: column;
}

.column-white-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000008;
  border: 1px solid #6994ec26;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px 10px 10px 10px;
}
.thumbnail-candidate-pic {
  float: left;
  margin-right: 10px;
}
.candidate-submitted-name {
  letter-spacing: 0px;
  color: #2e384d;
  font-size: 14px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  margin-bottom: 5px;
}
.candidate-submitted-id {
  letter-spacing: 0px;
  color: #4b58ab;
  font-size: 12px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
}
.posted-on-date {
  letter-spacing: 0px;
  color: #46484b;
  font-size: 12px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
}
.column-white-box.active {
  background: #4b58ab 0% 0% no-repeat padding-box;
}
.column-white-box.active > p {
  color: #fff !important;
}

.column-white-box.active > h6 {
  color: #fff !important;
}

.wid35 {
  width: 320px;
  margin-right: 50px;
}
.vertical-line-wr {
  display: block;
  border-bottom: 1px solid #ddd;
}

.wid25-attchments {
  width: 24% !important;
}
.table-custom-profile-style-td {
  letter-spacing: 0px;
  color: #03354c;
  font-size: 14px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
}

.table-custom-name-profile-td {
  letter-spacing: 0px;
  color: #9fb1ba;
  font-size: 14px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
}
.histroy-by-name-text {
  color: #7e92b9;
}

.veritical-document-nav .nav-link.active {
  color: #4b58ab;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  top: 0px;
}

.has-search .form-control-calenderline {
  position: absolute;
  z-index: 2;
  display: block;
  pointer-events: none;
  top: 14px;
  left: 220px;
}

.has-search .form-control {
  padding-left: 2.375rem;
}
.wid248 {
  width: 248px;
  margin-right: 10px;
}

.dis-search-item {
  display: inline-flex;
  margin-top: 15px;
}
.custom-pagi {
  float: right;
}
.chechox-selct-item {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #4b58ab44;
  border-radius: 4px;
  width: 23px;
  height: 24px;
  right: 10px;
  top: 10px;
}
.spa-id {
  font-size: 14px;
  font-weight: 400;
}
.blue-hfn-id-text {
  letter-spacing: 0px;
  color: #4b58ab;
  line-height: 20px;
  font-size: 14px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  margin-bottom: 0px;
}
.cent-item {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.top-set-table {
  position: relative;
  top: -19px;
}

.button-text-right {
  text-align: right;
}

.white-bg-drop-button button {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #e8effc !important;
  border-radius: 6px !important;
}
.bg-name-details-verify {
  display: flex;
  flex-direction: row;
}

.verify-img-bg {
  margin: 20px 20px 0px 0px;
}
.verified-user-wrapper {
  display: flex;
}
.bg-approval-tab {
  border-bottom: none;
  position: relative;
}
.bg-approval-tab.nav-tabs .nav-link.active {
  border-color: transparent;
  color: #4b58ab;
}

.bg-approval-tab.nav-tabs .nav-link {
  color: #7d96a3;
  background-color: transparent;
}

.circle-approval-check {
  width: 42px;
  height: 42px;
  display: block;
  background: rgba(137, 142, 151, 0.2862745098) 0% 0% no-repeat padding-box;
  border-radius: 100%;
  margin-right: 10px;
}

.approval-item-wrapper {
  display: inline-flex;
}
.check-tik {
  position: relative;
  top: 6px;
  color: #fff;
}
.approval-name-details:after {
  position: absolute;
  content: "";
  width: 30px;
  border: 1px solid #898e9749;
  top: 15px;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.approval-name-details {
  position: relative;
  margin: 6px 0px;
}

// .bg-approval-tab button.active ~ .circle-approval-check {
//   background: #4B58AB 0% 0% no-repeat padding-box;
//   color: #fff;
// }

.bg-approval-tab.nav-tabs .nav-link.active .circle-approval-check {
  background: #4b58ab 0% 0% no-repeat padding-box;
  color: #fff;
}

.bg-approval-tab.nav-tabs .nav-link.active .approval-name-details:after {
  border: 1px solid #4b58ab;
}

.details-name-wrapper {
  margin-right: 20px;
  width: 33%;
}
.details-name-wrapper-accord {
  margin-right: 15px;
  width: 30%;
}

.bg-approval-tab.nav-tabs .nav-link {
  padding: 10px 30px 10px 20px;
}

.bg-approval-tab.nav-tabs .nav-link:first-child {
  padding-left: 0px;
}

.btn-dark-blue {
  background: #4b58ab 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  padding: 10px 30px;
  font-size: 14px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  margin-top: 10px;
}

button:active {
  outline: none;
  border: none;
}

button:visited {
  outline: none;
  border: none;
}

button:hover {
  outline: none;
  border: none;
}
.details-required-text {
  color: #707070;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0px;
}
.details-answer-text {
  color: #03354c;
  font-size: 14px;
  line-height: 20px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
}
.posted-name-text {
  color: #898e97;
  font-size: 12px;
  line-height: 14px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
}
.posted-calender-text {
  color: #2e384d;
  font-size: 12px;
  line-height: 14px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  margin-left: 10px;
  margin-right: 10px;
}
.personal-histroy-tabs td {
  padding: 10px 10px;
  vertical-align: middle;
}
.extend-date-text {
  margin-left: 20px;
  color: #3f8fc6;
}
.bg-approval-comments .form-group label {
  align-self: start;
  background-image: linear-gradient(
    to top,
    #fff 0 calc(50% - 2px),
    #fff calc(50% - 2px)
  );
  font-size: 14px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  transform: translate(0.5rem, 84%);
  width: max-content;
}
.awaiting-expert-box {
  border: 1px solid #6994ec26 !important;
}
.bride-resonse-text {
  color: #000000;
  font-size: 12px;
  line-height: 16px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  margin-bottom: 5px;
}

.bride-age-num {
  color: #2e384d;
  font-size: 14px;
  line-height: 18px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
}
.bride-gallery-icon {
  position: relative;
  top: -4px;
}
.squre-icon-blue {
  width: 36px;
  height: 36px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

@media only screen and (max-width: 991px) {
  .white-dashboard-staus {
    width: 100%;
    margin: 10px 0px;
  }
  .flex-container {
    display: block;
  }
  .white-added-userbox {
    width: 100%;
  }

  .custom-superadmin-menu {
    background-color: #fff;
    z-index: 99;
    height: 60px;
  }
  .proposal-logo-mobile {
    max-width: 120px;
    margin: 10px 20px;
  }
  .bell-icon-top {
    margin: 0px 20px;
  }
  .logout-icon-amin-top {
    margin: 0px 20px;
  }
  .bg-dark-blue {
    padding: 0px 0px;
  }
  .super-admin-navitem {
    height: auto;
  }
  // .nav-right-icons {
  //   position: relative;
  //   right: 0px;
  // }

  .vertical-nav-item-bg-white {
    height: 100%;
    width: 100%;
    left: 16%;
  }

  .admin-bottom-text-item {
    position: relative;
    bottom: 0px;
    right: 0px;
    left: 10px;
  }

  // .sidebar-wrapper-style {
  //   display: none;
  // }

  .white-verification-userbox {
    width: 93%;
  }

  .wid35 {
    width: 100%;
  }

  .wid25-attchments {
    width: 90% !important;
  }

  .document-attachments-box-wrapper {
    width: 80%;
  }

  .mobile-heartfulness-logo-wi {
    max-width: 120px;
  }

  .two-equl-wrapper {
    margin-inline: 0px;
  }

  .button-text-right {
    text-align: left;
    margin-top: 20px;
  }

  .form-details-box {
    width: 76%;
  }
  .veritical-document-nav {
    margin-left: 12%;
    width: 92%;
  }

  .verification-box-user {
    width: 90%;
  }
  .admin-white-bg-wrapper {
    margin: 20px 0px 0px 0px;
  }

  .text-right {
    text-align: left !important;
  }

  .matchstatuspage-wrapper {
    .accept-pending-box {
      width: 95%;
    }
  }

  .photo-attchements-pic {
    margin-bottom: 10px;
  }
  .dashboard-wrapper {
    .dashboard-status-text {
      width: 94% !important;
    }
  }
.admin-logout-feature-icons{
  right: 35px;
  display: inline-flex;
  flex-direction: row;
  float: right;
}

}
.table td,
.table th {
  border-top: none;
}
.br-red {
  background-color: #e7bdbd;
  width: 17%;
  height: 45px;
  border: 1px solid rgba(236, 9, 9, 0.149);
  border-radius: 5px;
  padding: 10px 10px;
  margin: 0px 5px 10px 5px;
  font-size: 12px;
  color: black;
  @media only screen and (max-width: 991px) {
    width: 80%;
  }
}
.br-green {
  background-color: green;
  width: 17%;
  height: 45px;
  border: 1px solid rgba(236, 9, 9, 0.149);
  border-radius: 5px;
  padding: 10px 10px;
  margin: 0px 5px 10px 5px;
  font-size: 12px;
  color: white;
  @media only screen and (max-width: 991px) {
    width: 80%;
  }
}
.br-orange {
  background-color: orange;
  width: 17%;
  height: 45px;
  border: 1px solid rgba(236, 9, 9, 0.149);
  border-radius: 5px;
  padding: 10px 10px;
  margin: 0px 5px 10px 5px;
  font-size: 12px;
  color: black;
  @media only screen and (max-width: 991px) {
    width: 80%;
  }
}
.br-red-list {
  color: red;
}
.br-green-list {
  color: green;
}
.br-orange-list {
  color: orange;
}
.popup-aprove-btn {
  display: inline-flex;
  width: 153px;
  padding-top: 15px;
}
.break-word {
  word-break: break-all;
  padding-left: 40px;
  color: #2e384d;
  font-weight: 500;
}
.cursor {
  cursor: pointer;
}
.cand-status {
  white-space: nowrap;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
}

.admin-previous-btn {
  border: 1px solid #4b58ab;
  border-radius: 5px;
  color: #4b58ab;
}

.admin-btn-dark-blue {
  background: #4b58ab 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  padding: 5px 25px;
  font-size: 14px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
}

.admin-prev-btn {
  border: 1px solid #4b58ab;
  border-radius: 5px;
  opacity: 1;
  background-color: white;
  color: #4b58ab;
  padding: 5px 20px;
}

@media only screen and (max-width: 640px) {
  .registered-popup {
    top: -60px;
  }
  .place-hold-bottom {
    margin-bottom: 20px;
  }
  .mg-top-mobile {
    margin-top: 20px;
  }
  .admin-white-bg-wrapper {
    left: 0px;
    height: auto;
  }
  .wid30 {
    width: 100%;
  }

  .mg-left-navbar {
    margin-left: 0%;
    padding-bottom: 10px;
  }
  .admin-blue-title-text {
    font-size: 18px;
    line-height: 24px;
    margin-left: 5px;
  }
  .proposal-grey-text {
    margin-left: 10px;
    position: relative;
    top: 5px;
  }

  .light-squreblue {
    top: 0px;
  }
  .details-name-wrapper {
    width: 100%;
  }

  // .bm-burger-button {
  //   display: block;
  // }

  .sidebar-wrapper-style {
    top: 50px;
  }
}
.mg-left-wifi {
  margin-left: 20px;
}
.btn-rejec-width {
  width: 143px;
}

@media only screen and (max-width: 991px) {
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 10px;
    top: 5px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  // .bm-menu {

  // }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }

  .bm-menu-wrap {
    left: 0%;
  }

  .notification-container {
    margin-right: 18px !important;
}
.proposal-tile-mobile {
  display: block !important;
  font-size: 18px;
  position: relative;
  top: 9px;
  font-weight: 600;
}
.mobile-me {
  display: none;
  margin-right: 20px !important;
}
}

.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  width: 225px;
  left: -50px;
}
.notification-container {
  margin-right: 40px;
}

.nav-user-icon{
  border: none;
}

.nav-user-icon .navbar-toggler-icon {
  background: url('../../images/user.png') no-repeat !important;
  position: relative;
    padding: 0px 0px;
    width: 32px;
    height: 32px;
} 

.proposal-tile-mobile {
  display: none;
}