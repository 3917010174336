// Intro section style start
.intro-section {
  .section-title {
    margin-bottom: 25px;
  }

  .para-section {
    padding: 0 40px;

    p {
      font-family: "Avenir LT Std";
      font-size: 16px;
      line-height: 24px;
      color: #595959;
      margin-bottom: 25px;
    }
  }

  .intro-carousel {
    border: 1px solid #707070;
    padding: 25px;
    position: relative;

    .carousel-button-group {
      position: absolute;
      bottom: 45px;
      right: 40px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .intro-section {
    .para-section {
      padding: 0;
    }
  }

  .intro-section-row {
    .col-md-6 {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}

// Intro section style end

//Common list styles

.font-size-li {
  font-size: 13px !important;
  font-family: "gotham";
  color: #03354c;
}

.upload-img-list {
  padding: 5px;
}

#upload div {
  pointer-events: none;
}

#loading .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: transparent;
  border-radius: 0.3rem;
  outline: 0;
  color: white;
  align-items: center;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff !important;
  opacity: 1;
}

.form-control {
  font-size: 0.9rem;
  height: 40px !important;
}
.PhoneInputInput {
  width: 100% !important;
}
.p-5 {
  // padding: 0rem 3rem;
  padding: 0px 25px !important;
}
.mt-5 {
  margin-top: 1rem !important;
}
.mt-5-user {
  margin-top: 3rem !important;
}
.form-control.Current_Address {
  height: 139px !important;
}
.spinner-border {
  margin-top: 50%;
}

.Form-declareInput {
  font-size: 14px;
  line-height: 20px;
}
.extend-date-text-cursor{
    margin-left: 50px;
    color: #3f8fc6;
    cursor: pointer;
}

.lifestyle::before,
.lifestyle::after {
  position: absolute;
  top: 30px;
  overflow: hidden;
  width: 101px;
  height: 1px;
  content: "a0";
  background-color: #ced4da;
  left: 57px;
}

.upload-documents::before,
.upload-documents::after {
  position: absolute;
  top: 30px;
  overflow: hidden;
  width: 70px;
  height: 1px;
  content: "a0";
  background-color: #ced4da;
  left: 195px;
}

.photos-documents::before,
.photos-documents::after {
  position: absolute;
  top: 30px;
  overflow: hidden;
  width: 103px;
  height: 1px;
  content: "a0";
  background-color: #ced4da;
  left: 302px;
}
.register-page {
  font-weight: 600;
  color: #2e384d;
}
.HeaderDocuments::before,
.HeaderDocuments::after {
  position: absolute;
  top: 15px;
  overflow: hidden;
  width: 75%;
  height: 1px;
  content: "a0";
  background-color: #ced4da;
  left: 24%;
}
.PersonalDocuemnts::before,
.PersonalDocuemnts::after {
  position: absolute;
  top: 15px;
  overflow: hidden;
  width: 78%;
  height: 1px;
  content: "a0";
  background-color: #ced4da;
  left: 21%;
}
.align-right {
  text-align: right;
}
table {
  margin-top: 0;
  margin-bottom: 1rem;
}
table.BasicInfo-List {
  list-style: none;
  padding-left: 0;
}
table.BasicInfo-List td {
  border-bottom: 1px solid #ececec91;
  padding: 5px;
  color: #03354c;
  max-width: 200px;
  border-right: 1px solid #ececec91;
}

.lifestle-view {
  width: 50%;
  display: inline-block;
  text-transform: capitalize;
}
.lifestle-view {
  width: 50%;
  display: inline-block;
}
.InformationIconImg {
  top: 40%;
  position: absolute;
  left: -10px;
}

// Drag and drop css

.border-items {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #707070;
  border-radius: 10px;
  opacity: 1;
  height: 166px;
  width: 166px;
  // margin-left: 20px;
  margin-top:17px;
}

.btn-file {
  overflow: hidden;
  position: relative;
}

.plus-symbol-custom-icon {
  color: #4db884;
  font-size: 30px;
  left: 55px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  top: 55px;
  height: 100%;
}

.btn-file input[type="file"] {
  background: #fff;
  cursor: inherit;
  display: block;
  filter: alpha(opacity=0);
  height: 166px;
  opacity: 0;
  outline: none;
  position: absolute;
  text-align: right;
  top: 0;
  height: 166px;
  cursor: pointer;
}

.inner-drag {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 18px 5px;
}

.fVfcRr > span {
  font-size: 12px;
  color: #666;
}
.fVfcRr > span {
  font-size: 13px;
  color: #666;
  margin-left: 10px;
}
.inner-name {
  font-size: 12px;
  color: #666;
}
.border-custom-widht {
  width: 450px !important;
  height: 200px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #707070;
  border-radius: 10px;
  opacity: 1;
}
.border-custom-widht {
  width: 450px !important;
  height: 200px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #707070;
  border-radius: 10px;
  opacity: 1;
}
.document-selct-file {
  width: 450px !important;
  height: 200px !important;
}
.border-custom-widht .inner-drag {
  text-align: center;
  margin: 0 auto;
  display: block;
  position: relative;
  top: 30%;
  cursor: pointer;
}
.mg-top-10 {
  margin-top: 10%;
}
.float-rg {
  float: right;
  cursor: pointer;
}

.btn-resubmit-blue {
  background: #4b58ab 0% 0% no-repeat padding-box;
  border-radius: 5px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 0 5px;
  height: 30px;
}
.sadmin-dashboard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000008;
  border: 1px solid #f9f9f9;
  border-radius: 4px;
  opacity: 1;
  height: 80px;
}

.add-btn {
  text-align: left;
  font: normal normal normal 14px/15px Gotham;
  letter-spacing: 0px;
  color: #077260;
  opacity: 1;
  cursor: pointer;
}

.btn-aln-center {
  text-align: center;
}

.uploadFileName-red {
  font-size: 13px;
  color: red;
  text-transform: capitalize;
  text-align: center;
  margin-top: 20px;
}

.cursor {
  cursor: pointer;
}

#admin-comment .form-control {
  font-size: 0.9rem;
  height: 115px !important;
}
.Form-declare-checkbox{
  max-width: 20px;
  float: left;
  margin: -5px 10px;
}

.rejected-image {
  border: 5px solid red;
}

.remove-img {
  position: absolute !important;
  margin-left: -18px !important;
}
.payment-logout {
  border-style: solid;
  border-color: black;
}
.candidate-recom-top {
  margin-top: 4rem !important;
}

.status-red {
  color: red;
}
.status-green {
  color: green;
}
.status-orange {
  color: orange;
}
.weeding-authImg {
  max-width: 100%;
  width: 100%;
  height: 290px;
  margin-bottom: 1px;
}

.auto-left {
  margin-left: auto;
}

@media only screen and (max-width: 640px) {
  .PersonalDocuemnts::before,
  .PersonalDocuemnts::after {
    top: 28px;
    left: 0%;
  }
  .lifestyle::before,
  .lifestyle::after {
    display: none;
  }
  .upload-documents::before,
  .upload-documents::after {
    display: none;
  }
  .photos-documents::before,
  .photos-documents::after {
    display: none;
  }
}

.autocomplete-custom-st input:not(.elementor-field) {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAJCAYAAADpeqZqAAAABHNCSVQICAgIfAhkiAAAAPNJREFUGFdj/P//v9SvX79OMTIy8rGysgYC6b0MSAAob/br1+8d//8z/GZnZ7UDyt9k/Pnz55O5i9YI37n7iKO7tew3ExOTJ0wjSMPPX7/2tHZN5+Xm5mIozk18z8LCosMItOVTRV0v754DxxhcHKwYYBqBln2Gadi4dS+DpZkBw7T+hr9AQ2UYgaY5//v3b3tpdRcrksY/f//9+9XcMZULpmFqX/0/ZmbmYKArNjCCnI9NIzc3JwM2DSD1YE3YNILEQE5CtgGmFq4JXePXb9+wakCxCc3GHUAPswDFQFGwATkKsGqC2qgNpP8BNVxH1wDiAwC8f5g2htE9UwAAAABJRU5ErkJggg==);
  background-position: calc(100% - 16px) center;
  background-repeat: no-repeat;
  -webkit-appearance: none !important;
}

.log-in-text {
  color: #2e384d;
  font-size: 24px;
  margin: 0px 0px 0px 30px;
}
.logo-wrap-left {
  margin: 0px 0px 0px 30px;
}
.m_title:first-letter {
  text-transform: uppercase;
}

@media only screen and (max-width: 640px) {
  .UserHeaderSection h1 {
    font-size: 18px;
  }
  .matchprofileslider-wrapper {
    margin-top: 20px;
  }
  .weedingCol-Left {
    width: 100%;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.black-text-details {
  color: #03354c;
  font-weight: 600;
  font-family: "Avenir LT Std";
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #000;
}

input.firebaseui-input,
input.firebaseui-input-invalid {
  padding: 10px 10px;
}
.cursor-p {
  cursor: pointer;
}
@media only screen and (max-width: 640px) {
  .uploadFileName {
    text-align: left;
  }
  .up-load-text-doc {
    font-size: 18px;
  }
}

// accordian css

.wrapper {
  width: 600px;
  margin: 0 auto;
}

.accordion-wrapper {
  & + * {
    margin-top: 0.5em;
  }
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #666;
  padding: 0.5em 1.5em;
  border-bottom: solid 1px #ccc;
  // border-radius: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
  }

  &:hover,
  &.open {
    color: black;
  }

  &.open {
    &::after {
      content: "";
      border-top: 0;
      border-bottom: 5px solid;
    }
  }
}

.accordion-content {
  padding: 1em 1.5em;
}

input[type="date"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="time"].form-control {
  text-transform: uppercase;
}

.date-input,
input[id="inputforCalendar"] {
  background: #fff 0 0 no-repeat padding-box;
  // background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAaCAYAAACtv5zzAAAABHNCSVQICAgIfAhkiAAAAZpJREFUSEvtVk1ygjAYbcB97Qm0J1BPUDlB3XahwAlqT1B7A2+gwrC3J9Aj2BvQG9g9SN/LRIYCwWFw6MbMZAjJ+773/SRfIu40zfO8MEmSvW3bTgVmDsz76XQaua4bluFEhXDCtdlsVoXZA/IkhLCm0ynHhSaFV6tVt9PpvMKaIX67CjVW31JBtSbxkDuA5Mg5fIn/AuFW/gdBMIyiaIeFs2KdU7XmQfqB8C4EYk2mZ/RPkKzxlZYAsFMWWTrNwCyxNkB/g+yBOOTDwdjmmOElgYx1HMcPSJRUzsZ5tB9YofUMGIajkAPMk2xgmuYoJcgnc7PZLGFJiHlaWdp83x/TYvR5zriUWEtQK+A5cNaz9ggQjkUTq7OyyJ2D3jcMw0o9uJbyrB5snJeUgPv2WiSIhgNdPZ7w9nJQVXPqevY/u+jmQTZPtxxc3LXthgh1/4gjfY+68ah7GVw0uViuQ5YK6sxemXt1YdfV9wfPKooJ1qJvnK2+wIuij6uNN1CvkeaMMK9alOoJnzLpswUkE8XeiIfPF4Rmew73L/LFEuIw6eHGAAAAAElFTkSuQmCC)
  //   no-repeat scroll 7px 20px;
  background: url('../../images/calender-icon-new.png') no-repeat scroll 7px 20px;
  background-position: 93%;
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  padding: 0 0 0 10px;
  width: 100%;
  cursor: pointer;
}

.first-caps {
  text-transform: capitalize;
}
.profile-img-01 {
  object-fit: fill;
  border-radius: 50%;
  height: 120px;
  width: 120px;
}
.modal-title-extend-date {
  color: white;
}
.btn-height {
  height: 37px;
}
.name-pad-5p {
  padding-right: 15px;
}

#editClose .rbt-close-content {
  display:none;
}

#editClose .btn-close {
  box-sizing: content-box;
  width: 0.5em;
  height: 0.5em;
}