@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Bold.eot');
  src: local('Gotham-Bold'),
      url('../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gotham-Bold.woff2') format('woff2'),
      url('../fonts/Gotham-Bold.woff') format('woff'),
      url('../fonts/Gotham-Bold.ttf') format('truetype'),
      url('../fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Book.eot');
  src: local('Gotham-Book'),
      url('../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gotham-Book.woff2') format('woff2'),
      url('../fonts/Gotham-Book.woff') format('woff'),
      url('../fonts/Gotham-Book.ttf') format('truetype'),
      url('../fonts/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Black.eot');
  src: local('Gotham-Black'),
      url('../fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gotham-Black.woff2') format('woff2'),
      url('../fonts/Gotham-Black.woff') format('woff'),
      url('../fonts/Gotham-Black.ttf') format('truetype'),
      url('../fonts/Gotham-Black.svg#Gotham-Black') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Thin.eot');
  src: local('Gotham-Thin'),
      url('../fonts/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gotham-Thin.woff2') format('woff2'),
      url('../fonts/Gotham-Thin.woff') format('woff'),
      url('../fonts/Gotham-Thin.ttf') format('truetype'),
      url('../fonts/Gotham-Thin.svg#Gotham-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Light.eot');
  src: local('Gotham-Light'),
      url('../fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gotham-Light.woff2') format('woff2'),
      url('../fonts/Gotham-Light.woff') format('woff'),
      url('../fonts/Gotham-Light.ttf') format('truetype'),
      url('../fonts/Gotham-Light.svg#Gotham-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-XLight.eot');
  src: local('Gotham-XLight'),
      url('../fonts/Gotham-XLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gotham-XLight.woff2') format('woff2'),
      url('../fonts/Gotham-XLight.woff') format('woff'),
      url('../fonts/Gotham-XLight.ttf') format('truetype'),
      url('../fonts/Gotham-XLight.svg#Gotham-XLight') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Medium.eot');
  src: local('Gotham-Medium'),
      url('../fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gotham-Medium.woff2') format('woff2'),
      url('../fonts/Gotham-Medium.woff') format('woff'),
      url('../fonts/Gotham-Medium.ttf') format('truetype'),
      url('../fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
      url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Montserrat-Bold.woff2') format('woff2'),
      url('../fonts/Montserrat-Bold.woff') format('woff'),
      url('../fonts/Montserrat-Bold.ttf') format('truetype'),
      url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('../fonts/AvenirLTStd-Roman.eot');
  src: local('Avenir LT Std 55 Roman'), local('AvenirLTStd-Roman'),
      url('../fonts/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
      url('../fonts/AvenirLTStd-Roman.woff2') format('woff2'),
      url('../fonts/AvenirLTStd-Roman.woff') format('woff'),
      url('../fonts/AvenirLTStd-Roman.ttf') format('truetype'),
      url('../fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}




